import React from "react"
import { Flex, Heading, Text } from "rebass"

const Header = ({
  height = "100vh",
  fontWeight = "800",
  fontSize = [130, 270, 520],
}) => {
  const Title = () => {
    return (
      <Heading
        fontSize={fontSize}
        fontWeight={fontWeight}
        color="background"
        sx={{ textAlign: "center", paddingLeft: 3, paddingRight: 3 }}
      >
        We are{" "}
        <Text as="span" fontWeight="800">
          <Text as="span" color="primary">
            [
          </Text>
          hiring
          <Text as="span" color="primary">
            ]
          </Text>
        </Text>
      </Heading>
    )
  }

  return (
    <Flex
      bg="text"
      px={7}
      pt={[5, 0]}
      sx={{
        height,
        minHeight: height,
        overflow: "hidden",
        position: "relative",
      }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Title />

      <Text textAlign="center" color="white" fontSize={[1, 2]} mt={[6, 8]}>
        Sei tu il prossimo Spinupper?
      </Text>
    </Flex>
  )
}

export default Header
