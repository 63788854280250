import React, { useRef, useState } from "react"
import { useThemeUI } from "theme-ui"
import { css } from "emotion"
import { Box, Button, Flex, Image, Text } from "rebass"
import { EuiCommentList } from "@elastic/eui/lib/components/comment_list"
import Product4 from "../../../assets/images/products/4.png"
import Layout from "../../../components/_layout"
import SEO from "../../../components/seo"
import Container from "../../../components/_layout/container"
import Clients from "../../../components/clients"
import Labels from "../../../components/labels"
import Products from "../../../components/products"
import FeaturesY from "../../../components/features-y"
import Header from "../header"
import OgImage from "../../../assets/images/marketing.jpg"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CareersMarketing = () => {
  const [isFrameInitialized, setIsFrameInitialized] = useState(false)
  const { theme } = useThemeUI()
  const iframeRef = useRef()

  const comments = [
    {
      username: "Spinup",
      type: "update",
      event: "viene fondata",
      timestamp: "il 5 gennaio 2017",
    },
    {
      username: "2017",
      type: "update",
      event: "fatturato di 200.000€",
    },
    {
      username: "2018",
      type: "update",
      event: "fatturato di 855.000€",
    },
    {
      username: "2019",
      type: "update",
      event: "fatturato di 1.450.000€",
    },
    {
      username: "2020",
      type: "update",
      event: "fatturato di 3.600.000€",
    },
  ]

  const apply = () => (
    <Box sx={{ textAlign: "center" }}>
      <Text sx={{ textAlign: "center", fontSize: [1], fontWeight: "400" }}>
        Pensi di essere la persona giusta?
      </Text>

      <Button
        mt={3}
        fontSize={1}
        px={5}
        onClick={e => {
          e.preventDefault()

          setIsFrameInitialized(true)

          setTimeout(() => {
            iframeRef.current.scrollIntoView({ behavior: "smooth" })
          }, 100)
        }}
      >
        Candidati ora
      </Button>
    </Box>
  )

  return (
    <Layout>
      <SEO
        title="Cerchiamo un marketing manager che si occupi di lead generation. Sei tu?"
        image={OgImage}
      />

      <Header height={["65vh", "70vh"]} fontWeight="300" fontSize={[32, 128]} />

      <Box my={[5, 7]}>
        <Container size="small">
          <Text textAlign="center" fontSize={[1]}>
            Spinup è alla ricerca di un marketing manager che si occupi di lead
            generation.
          </Text>

          <Text textAlign="center" mt={[6, 7]} fontSize={[1]}>
            Spinup è una media-tech company con sede a Napoli e a Milano ed è
            una delle società più in crescita in Italia.
          </Text>

          <Flex
            mt={[6, 7]}
            flexDirection={["column", "row"]}
            justifyContent="center"
          >
            <Flex
              textAlign="center"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              p={5}
            >
              <img
                src="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/madcon.png"
                alt=""
                className={css`
                  max-width: 200px;
                  width: 200px;
                `}
              />
              <Text
                mt={3}
                sx={{
                  maxWidth: "350px",
                  textAlign: "center",
                }}
              >
                Spinup è tra le migliori 100 società al mondo per marketing e
                advertising secondo il MADcon Dubai
              </Text>
            </Flex>

            <Flex
              textAlign="center"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              p={5}
            >
              <img
                src="https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/lefonti_2020.png"
                alt=""
                className={css`
                  max-width: 200px;
                  width: 200px;
                `}
              />
              <Text
                mt={3}
                sx={{
                  maxWidth: "350px",
                  textAlign: "center",
                }}
              >
                {
                  "“Le Fonti Awards 2020”: Eccellenza dell'anno Innovazione & Performance Marketing"
                }
              </Text>
            </Flex>
          </Flex>

          <Flex mt={[6, 7]} justifyContent="center">
            <EuiCommentList comments={comments} />
          </Flex>
        </Container>
      </Box>

      <Box bg="dark" color="white">
        <Container size="small">
          <Text textAlign="center" mt={[6, 7]} fontSize={[1]}>
            Operiamo nel settore del performance marketing con un modello
            pienamente basato sul risultato ed abbiamo all'attivo importanti
            collaborazioni con clienti leader di settore.
          </Text>

          <Clients />
        </Container>
      </Box>

      <Box py={[6, 7]}>
        <Container size="small">
          <Text textAlign="center" fontSize={[1]}>
            Spinup gestisce in autonomia oltre 500.000 € di budget media al
            mese, e la sua vera forza è l’unione tra marketing e tecnologia.
          </Text>

          <Labels
            labels={[
              "Modello 100% performance",
              "500.000€ / mese budget investito",
              "Marketing + tecnologia = Spinup",
            ]}
            color="dark"
          />

          <Text textAlign="center" mt={[6, 7]} fontSize={[1]}>
            Il team di developer full stack di Spinup lavora infatti ogni giorno
            a strettissimo contatto con il team marketing con un unico
            obiettivo: creare un’ecosistema tecnologico in cui l’intervento
            umano del marketer sia richiesto solo per attività dall’alto valore
            aggiunto.
          </Text>
        </Container>
      </Box>

      <Box bg="dark" color="white">
        <Products
          products={[
            {
              sx: {
                flexDirection: ["column !important", "row-reverse !important"],
                "> div:first-child": {
                  ml: [0, 8],
                },
              },
              image: Product4,
              hidePattern: true,
              title: "Brain",
              subtitle:
                "La nostra tecnologia, cuore di tutto quello che facciamo",
              description:
                "Brain è la piattaforma tecnologica al centro di tutti i processi di Spinup. La nostra vision è quella di creare una piattaforma dove l’uomo (i nostri marketer) e la tecnologia (gli algoritmi e le funzioni scritti dai nostri dev) cooperino in maniera ottimale. Brain è usato per l’automazione della BI aziendale, come strumento per l’invio delle lead ai nostri clienti, per lanciare e scalare in maniera automatica le nostre campagne di media buying, per creare gli asset (come i chatbot) su cui atterrano gli utenti intercettati dalle nostre ads.",
            },
          ]}
        />
      </Box>

      <Box py={[6, 7]} px={[3, 0]}>
        <Container maxWidth={760}>
          <Text textAlign="center" fontSize={[1]}>
            I nostri team, insieme:
          </Text>

          <Flex sx={{ mt: 9, width: "100%", flexWrap: "wrap" }}>
            {[
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/coding.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Costruiscono tool proprietari con lo scopo di aumentare costantemente le performance delle attività di media buying",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/api.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Implementano le Facebook Marketing API e le Google Marketing API per scalare massivamente il processo di creazione annunci",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/business-report.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Lavorano per eliminare la parte noiosa del lavoro di un media buyer (i report lasciamoli fare agli algoritmi)",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/robot.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Automatizzano mediante tecnologie proprietarie tutto ciò che viene ritenuto un intervento senza valore aggiunto",
              },
            ].map((val, index) => (
              <Box
                key={index}
                sx={{
                  px: [2, 5],
                  mb: 7,
                  textAlign: "center",
                  width: ["100%", "50%"],
                }}
              >
                {val.icon}

                <Text fontSize={1} mt={3}>
                  {val.description}
                </Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>

      <Box
        bg="black"
        color="white"
        py={6}
        sx={{
          borderTop: `5px solid ${theme.colors.primary}`,
          borderBottom: `5px solid ${theme.colors.primary}`,
        }}
      >
        <Container size="small">
          <Text fontSize={[2, 3]} textAlign="center">
            Siamo alla ricerca di un{" "}
            <Text as="span" fontWeight="700">
              marketing manager
            </Text>{" "}
            che si occupi di lead generation.
          </Text>
        </Container>
      </Box>

      <Box py={[6, 7]} px={[3, 0]}>
        <Container size="small">
          <Text textAlign="center" fontSize={[1]}>
            Cerchiamo qualcuno che cerchi sempre di migliorarsi, che abbia
            voglia di esplorare i confini del performance marketing e che sia
            tanto curioso di imparare cose nuove quanto appassionato nel lavoro
            di tutti i giorni.
          </Text>

          <Text textAlign="center" mt={[7]} fontSize={[1]}>
            La figura che cerchiamo avrà le responsabilità di:
          </Text>

          <Flex sx={{ mt: 9, width: "100%", flexWrap: "wrap" }}>
            {[
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/ads.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Seguire a 360° le attività di media buying relative a Google Ads e Facebook Ads",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/metrics.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Approcciare in maniera organizzata, sistematica e puntuale al miglioramento di metriche ed obiettivi",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/support.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Collaborare con il resto del team alla definizione della ads strategy per ciascun progetto",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/investment.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Pianificare i forecast di investimento giornalieri sui canali gestiti",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/analytics.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Operare su strumenti di business intelligence interni per identificare i punti di equilibrio delle campagne",
              },
              {
                icon: (
                  <Image
                    src={require("../../../assets/images/investigate.svg")}
                    sx={{
                      height: 80,
                    }}
                  />
                ),
                description:
                  "Identificare insight per il miglioramento dei CRO dei tool usati / suggerimenti strategici per la creazione di nuovi tool",
              },
            ].map((val, index) => (
              <Box
                key={index}
                sx={{
                  px: [2, 5],
                  mb: 7,
                  textAlign: "center",
                  width: ["100%", "50%", "33.3%"],
                }}
              >
                {val.icon}

                <Text fontSize={1} mt={3}>
                  {val.description}
                </Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>

      <Box bg="dark" color="white">
        <Text textAlign="center" pt={[9]} fontSize={[2, 3, 4]}>
          Cosa cerchiamo
        </Text>

        <FeaturesY
          theme="dark"
          titleSize={["60px", "160px"]}
          features={[
            {
              title: [
                "Comprovata esperienza di almeno 5 anni",
                "in ambito Facebook Ads / Google Ads",
              ],
              bigTitle: "Esperienza",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: [
                "Aver gestito con successo (obiettivi e risultati raggiunti)",
                "progetti in settori differenti",
              ],
              bigTitle: "Risultati",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: [
                "Premiamo la curiosità e la costante",
                "voglia di imparare e migliorarsi",
              ],
              bigTitle: "Curiosità",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: ["Concretezza come approccio di vita"],
              bigTitle: "Concretezza",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
          ]}
        />
      </Box>

      <Box mt={7} px={3}>
        <Text fontSize={[1]} mb={7} color="darkgray" textAlign="center">
          <FontAwesomeIcon icon={faGlobe} /> Luogo di lavoro: headquarters di
          Napoli o sede di Milano
        </Text>

        {apply()}
      </Box>

      <div ref={iframeRef}>
        <Box pt={8}>
          {isFrameInitialized && (
            <Box sx={{ backgroundColor: theme.colors.dark }}>
              <iframe
                title="Careers"
                src="https://vinta-git-project-smooth-y.wearespinup.vercel.app/c/spinup-careers-marketing"
                className={css`
                  width: 100%;
                  height: 75vh;
                `}
              />
            </Box>
          )}
        </Box>
      </div>
    </Layout>
  )
}

export default CareersMarketing
