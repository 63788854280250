import React from "react"
import { useThemeUI } from "theme-ui"
import { Box, Flex, Text } from "rebass"

const Labels = ({ labels = [], color = "background" }) => {
  const { theme } = useThemeUI()

  return (
    <Flex
      flexDirection={["column", "row"]}
      justifyContent="center"
      alignItems="center"
      px={[0, 4]}
      my={7}
      mx={[0, 10]}
    >
      {labels.map((label, i) => (
        <Flex
          width={[1 / 2, 1 / labels.length]}
          key={i}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            px: [3],
            height: 40,
            mx: [2],
            mt: [3, 0],
            borderRadius: theme.radii.default,
            border: `1px solid ${theme.colors.primary}`,
            color: color,
            lineHeight: 1,
          }}
        >
          <Box
            sx={{
              flexShrink: 0,
              mr: 2,
              width: [6, 10],
              height: [6, 10],
              borderRadius: 12,
              backgroundColor: theme.colors.lightgreen,
            }}
          />

          <Text fontSize={["12px", 0]}>{label}</Text>
        </Flex>
      ))}
    </Flex>
  )
}

export default Labels
